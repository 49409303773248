import createDataContext from "./createContext";
import BaseRequest from "../api/Base";
import { sendNotification } from "../hooks/useNotification";
import { openModal } from "../hooks/useModals";
import { Button, Stack } from "@mui/material";
import { dateToServerFormat, getFifteenDaysAgo } from "../utilities";
import { BaseNoLoaderService } from "../api";

const SET_COST_CENTER = "set_cost_center";
const SET_TITLE_CENTER_COST = "set_title_cost_center";
const SET_ANALYTIC_COST = "set_analytic_cost";
const SET_COST_CENTER_FOR_ID = "set_cost_center_for_id";
const SET_ANALYTICS_COSTS = "set_analytics_costs";
const SET_ANALYTICS_COSTS_FROM_COSTS_CENTER =
  "set_analytics_costs_from_costs_center";
const ANALYTICS_COSTS_ENDPOINT = "/analyticalcosts";
const SET_FACTIVITIES_COST = "set_factivities_cost";
const SET_ANALYTIC_REVENUE = "set_analytic_cost";
const SET_ANALYTICS_REVENUES = "set_analytics_revenues";
const SET_FACTIVITIES_REVENUE = "set_activities_revenue";
const SET_ANALYTICS_REVENUE_FROM_COSTS_CENTER =
  "set_analytics_costs_from_costs_center";
const ANALYTICS_REVENUES_ENDPOINT = "/revenues";
const SET_COST_CENTER_USER = "set_cost_center_user";
const SET_COST_CENTER_FOR_USER = "set_cost_center_for_user";

const INITIAL_STATE = {
  costcenter: {},
  costcenters: [],
  costCenterForId: "",
  costcenterforuser: {},
  costcentersforuser: [],
  costCenterAnalyticsCosts: [],
  costCenterAnalyticsCostsList: [],
  analyticCost: {
    description: "",
    datefrom: "",
    dateto: "",
    amount: null,
    idcostcenter: null,
  },
  analyticsCosts: [],
  searchedAnalyticsCosts: [],
  factivities: [],
  costCenterAnalyticsRevenue: [],
  costCenterAnalyticsRevenueList: [],
  analyticsRevenues: [],
  analyticRevenue: {
    description: "",
    datefrom: "",
    dateto: "",
    amount: null,
    idcostcenter: null,
    idtype: 2,
  },
  searchedAnalyticsRevenue: [],
  searchAnalyticsRevenue: {
    description: "",
    validityfrom: "",
    validityto: dateToServerFormat(new Date()),
    idcostcenter: null,
  },
  titleCenterCost: "",
  costCenterUser: [],
};

const CostCenterReducer = (state, action) => {
  switch (action.type) {
    case SET_COST_CENTER: {
      return {
        ...state,
        costcenters: [...action.payload],
        costcenter: {},
      };
    }
    case SET_COST_CENTER_FOR_USER: {
      return {
        ...state,
        costcentersforuser: { ...action.payload },
        costcenterforuser: {},
      };
    }
    case SET_COST_CENTER_FOR_ID: {
      return {
        ...state,
        costCenterForId: action.payload,
      };
    }
    case SET_TITLE_CENTER_COST: {
      return {
        ...state,
        titleCenterCost: action.payload.titleCenterCost,
      };
    }
    case SET_ANALYTIC_COST: {
      const { analyticCost } = action.payload;
      return { ...state, analyticCost };
    }
    case SET_ANALYTICS_COSTS: {
      const { searchAnalyticsCosts, analyticsCosts } = action.payload;
      let searchedAnalyticsCosts = searchExecution(
        analyticsCosts,
        searchAnalyticsCosts
      );
      return {
        ...state,
        searchedAnalyticsCosts,
        analyticsCosts,
        analyticCost: INITIAL_STATE.analyticCost,
      };
    }
    case SET_ANALYTICS_COSTS_FROM_COSTS_CENTER: {
      const { costCenterAnalyticsCosts } = action.payload;
      return { ...state, costCenterAnalyticsCosts };
    }
    case SET_FACTIVITIES_COST: {
      const { factivities } = action.payload;
      return { ...state, factivities };
    }
    case SET_ANALYTIC_REVENUE: {
      const { analyticRevenue } = action.payload;
      return { ...state, analyticRevenue };
    }
    case SET_ANALYTICS_REVENUES: {
      const { searchAnalyticsRevenue, analyticsRevenues } = action.payload;
      let searchedAnalyticsRevenue = searchExecution(
        analyticsRevenues,
        searchAnalyticsRevenue
      );
      return {
        ...state,
        searchedAnalyticsRevenue,
        analyticsRevenues,
        analyticRevenue: INITIAL_STATE.analyticRevenue,
      };
    }
    case SET_ANALYTICS_REVENUE_FROM_COSTS_CENTER: {
      const { costCenterAnalyticsRevenue } = action.payload;
      return { ...state, costCenterAnalyticsRevenue };
    }
    case SET_FACTIVITIES_REVENUE: {
      const { factivities } = action.payload;
      return { ...state, factivities };
    }
    case SET_COST_CENTER_USER: {
      const { costCenterUser } = action.payload;
      return { ...state, costCenterUser };
    }
    default: {
      return state;
    }
  }
};

const setFactitives = (dispatch) => async () => {
  const response = await BaseRequest.get(`/analyticalcosts/factitives`);
  dispatch({
    type: SET_FACTIVITIES_COST,
    payload: { factivities: response.data.data },
  });
};
const setFactitivesRevenue = (dispatch) => async () => {
  const response = await BaseRequest.get(`/revenues/factitives`);
  dispatch({
    type: SET_FACTIVITIES_COST,
    payload: { factivities: response.data.data },
  });
};

const setCostCenter =
  (dispatch) =>
  async (iduser, rights = null, datetimefrom = null, datetimeto = null) => {
    const param = {};
    if (rights) {
      param.rights = rights;
    }
    if (datetimefrom) {
      param.datetimefrom = datetimefrom;
    }
    if (datetimeto) {
      param.datetimeto = datetimeto;
    }
    const response = await BaseRequest.get(`/users/${iduser}/costcenters`, {
      params: param,
    });
    dispatch({ type: SET_COST_CENTER, payload: response.data.data });
  };

const setCostCenterForUser =
  (dispatch) =>
  async (
    position = null,
    iduser,
    rights = null,
    datetimefrom = null,
    datetimeto = null
  ) => {
    const param = {};
    if (rights) {
      param.rights = rights;
    }
    if (datetimefrom) {
      param.datetimefrom = datetimefrom;
    }
    if (datetimeto) {
      param.datetimeto = datetimeto;
    }
    const geolat = position?.latitude || null;
    const geolong = position?.longitude || null;
    const response = await BaseRequest.get(
      `/users/${iduser}/activities?geolat=${geolat}&geolong=${geolong}`,
      { params: param }
    );
    dispatch({ type: SET_COST_CENTER_FOR_USER, payload: response.data.data });
  };

const setCostCenterUser =
  (dispatch) =>
  async (iduser, rights = null) => {
    const param = {};
    if (rights) {
      param.rights = rights;
    }
    const response = await BaseNoLoaderService.get(
      `/users/${iduser}/costcenters`,
      { params: param }
    );
    dispatch({
      type: SET_COST_CENTER_USER,
      payload: { costCenterUser: response.data.data },
    });
  };

const setAnalyticCost = (dispatch) => async (costCenterId, id) => {
  let analyticsCosts = await BaseRequest.get(
    `/costcenters/${costCenterId}${ANALYTICS_COSTS_ENDPOINT}/${id}`
  );
  dispatch({
    type: SET_ANALYTIC_COST,
    payload: {
      analyticCost: analyticsCosts.data.data,
    },
  });
};

const setCostCenterForId = (dispatch) => async (costCenterId) => {
  if (costCenterId) {
    let costCenterForId = await BaseRequest.get(`/costcenters/${costCenterId}`);
    const description = costCenterForId.data.data?.description;
    dispatch({
      type: SET_COST_CENTER_FOR_ID,
      payload: description,
    });
  }
};

const addAnalyticsCosts =
  (dispatch) =>
  async (payload, costCenterId, navigate, isAnalyticView = false) => {
    let analyticsCostsToCostCenter = await BaseRequest.post(
      `/costcenters/${costCenterId}${ANALYTICS_COSTS_ENDPOINT}`,
      payload.values
    );
    if (analyticsCostsToCostCenter) {
      setAnalyticsCostsFromCostCenter(dispatch)(costCenterId);
      sendNotification(
        "Completato",
        "Costo Analitico creato con successo",
        "success",
        5
      );
      if (isAnalyticView) {
        navigate(-1);
      }
    }
  };
const putAnalyticsCostsFromCostCenter =
  (dispatch) =>
  async (
    payload,
    costCenterId,
    navigate,
    isAnalyticView = null,
    dictionary
  ) => {
    const action = async () => {
      await BaseRequest.put(
        `/costcenters/${costCenterId}${ANALYTICS_COSTS_ENDPOINT}/${payload.values.id}`,
        payload.values
      );
      setAnalyticsCostsFromCostCenter(dispatch)(costCenterId);
      payload.setValues(INITIAL_STATE.analyticCost);
      sendNotification(
        "Modificato",
        "Costo Analitico modificato con successo",
        "success",
        5
      );
      if (isAnalyticView) {
        navigate(-1);
      }
    };
    openModal(
      "Modifica Costo Analitico",
      "Sei sicuro di voler modificare il Costo Analitico, ció potrebbe influenzare i calcoli !",
      "warning",
      (closeModal) => (
        <Stack direction="row" spacing={2}>
          <Button
            sx={{ textTransform: "capitalize" }}
            variant="outlined"
            onClick={async () => {
              await action();
              closeModal();
              setAnalyticsCostsFromCostCenter(dispatch)(costCenterId);
            }}
          >
            {dictionary["confirm"]}
          </Button>
          <Button
            sx={{ textTransform: "capitalize" }}
            variant="outlined"
            color="warning"
            onClick={closeModal}
          >
            {dictionary["cancel"]}
          </Button>
        </Stack>
      )
    );
  };

const setAnalyticsCostsFromCostCenter =
  (dispatch) =>
  async (costCenterId, showdisabled = false) => {
    let analyticsCosts = null;
    let enabled = !showdisabled;
    analyticsCosts = await BaseRequest.get(
      `/costcenters/${costCenterId}${ANALYTICS_COSTS_ENDPOINT}`
    );
    if (analyticsCosts.data) {
      dispatch({
        type: SET_ANALYTICS_COSTS_FROM_COSTS_CENTER,
        payload: {
          costCenterAnalyticsCosts: analyticsCosts.data.data,
        },
      });
    } else {
      dispatch({
        type: SET_ANALYTICS_COSTS_FROM_COSTS_CENTER,
        payload: {
          costCenterAnalyticsCosts: [],
        },
      });
    }
  };

const deleteAnalyticsCostFromCenter =
  (dispatch) => (costCenterId, id, dictionary) => {
    let user = localStorage.getItem("bitPresenzeclient/user");
    let userParse = JSON.parse(user);
    const action = async () => {
      await BaseRequest.delete(
        `/costcenters/${costCenterId}${ANALYTICS_COSTS_ENDPOINT}/${id}`
      );
      setCostCenter(dispatch)(userParse.id, "1");
      sendNotification(
        "Completato",
        "Costo Analitico eliminato con successo",
        "success",
        5
      );
    };
    openModal(
      "Elimina Costo Analitico",
      "Sei sicuro di voler eliminare il Costo Analitico ?",
      "warning",
      (closeModal) => (
        <Stack direction="row" spacing={2}>
          <Button
            sx={{ textTransform: "capitalize" }}
            variant="outlined"
            onClick={async () => {
              await action();
              closeModal();
              setAnalyticsCostsFromCostCenter(dispatch)(costCenterId);
            }}
          >
            {dictionary["confirm"]}
          </Button>
          <Button
            sx={{ textTransform: "capitalize" }}
            variant="outlined"
            color="warning"
            onClick={closeModal}
          >
            {dictionary["cancel"]}
          </Button>
        </Stack>
      )
    );
  };

const deleteAnalyticsCost = (dispatch) => (costCenterId, id, dictionary) => {
  const action = async () => {
    await BaseRequest.delete(
      `/costcenters/${costCenterId}${ANALYTICS_COSTS_ENDPOINT}/${id}`
    );
    sendNotification(
      "Completato",
      "Costo Analitico eliminato con successo",
      "success",
      5
    );
  };
  openModal(
    "Elimina Costo Analitico",
    "Sei sicuro di voler eliminare il Costo Analitico ?",
    "warning",
    (closeModal) => (
      <Stack direction="row" spacing={2}>
        <Button
          sx={{ textTransform: "capitalize" }}
          variant="outlined"
          onClick={async () => {
            await action();
            closeModal();
          }}
        >
          {dictionary["confirm"]}
        </Button>
        <Button
          sx={{ textTransform: "capitalize" }}
          variant="outlined"
          color="warning"
          onClick={closeModal}
        >
          {dictionary["cancel"]}
        </Button>
      </Stack>
    )
  );
};
const searchAnalyRevenues = (dispatch) => (searchAnalyticsRevenue) => {
  const { description, idcostcenter, validityto, validityfrom } =
    searchAnalyticsRevenue;

  setAnalyticsRevenues(dispatch)(
    searchAnalyticsRevenue,
    false,
    description,
    idcostcenter,
    validityto,
    validityfrom
  );
};

const setTitle = (dispatch) => async (title) => {
  dispatch({
    type: SET_TITLE_CENTER_COST,
    payload: {
      titleCenterCost: title,
    },
  });
};

/*------------------------------------------------------------------------------------------------------------------*/
/*-------------------------------------------- Ricavi Analitici -----------------------------------------------------*/

const setAnalyticsRevenues =
  (dispatch) =>
  async (
    searchAnalyticsRevenue,
    showdisabled = false,
    description,
    idcostcenter,
    validityto,
    validityfrom
  ) => {
    let analyticsRevenues = null;

    const params = {};

    if (idcostcenter) {
      params.idcostcenter = idcostcenter;
    }

    if (description) {
      params.description = description;
    }

    if (validityfrom) {
      params.validityfrom = validityfrom;
    }

    if (validityto) {
      params.validityto = validityto;
    }

    analyticsRevenues = await BaseRequest.get(ANALYTICS_REVENUES_ENDPOINT, {
      params,
    });

    dispatch({
      type: SET_ANALYTICS_REVENUES,
      payload: {
        searchAnalyticsRevenue,
        analyticsRevenues: analyticsRevenues.data.data,
      },
    });
  };

const setAnalyticRevenue = (dispatch) => async (costCenterId, id) => {
  let analyticRevenue = await BaseRequest.get(
    `/costcenters/${costCenterId}${ANALYTICS_REVENUES_ENDPOINT}/${id}`
  );
  dispatch({
    type: SET_ANALYTIC_REVENUE,
    payload: {
      analyticRevenue: analyticRevenue.data.data,
    },
  });
};

const addAnalyticsRevenue =
  (dispatch) =>
  async (payload, costCenterId, navigate, isAnalyticView = false) => {
    let analyticsCostsToCostCenter = await BaseRequest.post(
      `/costcenters/${costCenterId}${ANALYTICS_REVENUES_ENDPOINT}`,
      payload.values
    );
    if (analyticsCostsToCostCenter) {
      setAnalyticsRevenueFromCostCenter(dispatch)(costCenterId);
      sendNotification(
        "Completato",
        "Ricavo Analitico creato con successo",
        "success",
        5
      );
      if (isAnalyticView) {
        navigate(-1);
      }
      payload.reset(INITIAL_STATE.analyticRevenue);
    }
  };
const putAnalyticsRevenueFromCostCenter =
  (dispatch) =>
  async (
    payload,
    costCenterId,
    navigate,
    isAnalyticView = null,
    dictionary
  ) => {
    const action = async () => {
      await BaseRequest.put(
        `/costcenters/${costCenterId}${ANALYTICS_REVENUES_ENDPOINT}/${payload.values.id}`,
        payload.values
      );
      setAnalyticsRevenueFromCostCenter(dispatch)(costCenterId);
      payload.setValues(INITIAL_STATE.analyticRevenue);
      sendNotification(
        "Modificato",
        "Ricavo Analitico modificato con successo",
        "success",
        5
      );
      if (isAnalyticView) {
        navigate(-1);
      }
    };
    openModal(
      "Modifica Ricavo Analitico",
      "Sei sicuro di voler modificare il Ricavo Analitico, ció potrebbe influenzare i calcoli !",
      "warning",
      (closeModal) => (
        <Stack direction="row" spacing={2}>
          <Button
            sx={{ textTransform: "capitalize" }}
            variant="outlined"
            onClick={async () => {
              await action();
              closeModal();
              setAnalyticsRevenueFromCostCenter(dispatch)(costCenterId);
            }}
          >
            {dictionary["confirm"]}
          </Button>
          <Button
            sx={{ textTransform: "capitalize" }}
            variant="outlined"
            color="warning"
            onClick={closeModal}
          >
            {dictionary["cancel"]}
          </Button>
        </Stack>
      )
    );
  };

const setAnalyticsRevenueFromCostCenter =
  (dispatch) =>
  async (costCenterId, showdisabled = false) => {
    let analyticsCosts = null;
    let enabled = !showdisabled;
    analyticsCosts = await BaseRequest.get(
      `/costcenters/${costCenterId}${ANALYTICS_REVENUES_ENDPOINT}`
    );
    if (analyticsCosts) {
      dispatch({
        type: SET_ANALYTICS_REVENUE_FROM_COSTS_CENTER,
        payload: {
          costCenterAnalyticsRevenue: analyticsCosts.data.data,
        },
      });
    }
  };

const deleteAnalyticsRevenue =
  (dispatch) => (costCenterId, id, dictionary, dataAnalytics) => {
    const action = async () => {
      await BaseRequest.delete(
        `/costcenters/${costCenterId}${ANALYTICS_REVENUES_ENDPOINT}/${id}`
      );
      sendNotification(
        "Completato",
        "Ricavo Analitico eliminato con successo",
        "success",
        5
      );
    };
    openModal(
      "Elimina Ricavo Analitico",
      "Sei sicuro di voler eliminare il Ricavo Analitico ?",
      "warning",
      (closeModal) => (
        <Stack direction="row" spacing={2}>
          <Button
            sx={{ textTransform: "capitalize" }}
            variant="outlined"
            onClick={async () => {
              await action();
              closeModal();
              setAnalyticsRevenues(dispatch)(
                {},
                false,
                "",
                null,
                dataAnalytics.validityto,
                dataAnalytics.validityfrom
              );
            }}
          >
            {dictionary["confirm"]}
          </Button>
          <Button
            sx={{ textTransform: "capitalize" }}
            variant="outlined"
            color="warning"
            onClick={closeModal}
          >
            {dictionary["cancel"]}
          </Button>
        </Stack>
      )
    );
  };

const deleteAnalyticsRevenueFromCenter =
  (dispatch) => (costCenterId, id, dictionary, setCostsCenter) => {
    const action = async () => {
      await BaseRequest.delete(
        `/costcenters/${costCenterId}${ANALYTICS_REVENUES_ENDPOINT}/${id}`
      );
      setCostsCenter({}, false);
      sendNotification(
        "Completato",
        "Ricavo Analitico eliminato con successo",
        "success",
        5
      );
    };
    openModal(
      "Elimina Ricavo Analitico",
      "Sei sicuro di voler eliminare il Ricavo Analitico ?",
      "warning",
      (closeModal) => (
        <Stack direction="row" spacing={2}>
          <Button
            sx={{ textTransform: "capitalize" }}
            variant="outlined"
            onClick={async () => {
              await action();
              closeModal();
              setAnalyticsRevenueFromCostCenter(dispatch)(costCenterId);
            }}
          >
            {dictionary["confirm"]}
          </Button>
          <Button
            sx={{ textTransform: "capitalize" }}
            variant="outlined"
            color="warning"
            onClick={closeModal}
          >
            {dictionary["cancel"]}
          </Button>
        </Stack>
      )
    );
  };

export const { Provider, Context } = createDataContext(
  CostCenterReducer,
  {
    setCostCenter,
    setCostCenterForId,
    setCostCenterForUser,
    setAnalyticCost,
    deleteAnalyticsCost,
    deleteAnalyticsCostFromCenter,
    putAnalyticsCostsFromCostCenter,
    addAnalyticsCosts,
    setAnalyticsCostsFromCostCenter,
    setCostCenterUser,
    searchAnalyRevenues,
    deleteAnalyticsRevenueFromCenter,
    deleteAnalyticsRevenue,
    setAnalyticsRevenueFromCostCenter,
    putAnalyticsRevenueFromCostCenter,
    addAnalyticsRevenue,
    setAnalyticRevenue,
    setAnalyticsRevenues,
    setFactitives,
    setFactitivesRevenue,
    setTitle,
  }, // actions
  INITIAL_STATE // setCommission initial state
);
