import {
  CalendarMonth,
  CalendarViewMonth,
  Refresh,
  Search,
  Today,
} from "@mui/icons-material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { DailyActivitiesTabels, IconBox } from "../components";
import {
  ClockingsContext,
  CostCenterContext,
  DailyActivitiesContext,
} from "../contexts";
import { useDictionary, useForm, useUser, useCostCenters } from "../hooks";
import { isDateGreater, isRequired } from "../hooks/useForm";
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";
import AddCommentOutlinedIcon from "@mui/icons-material/AddCommentOutlined";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";

const today = new Date();
const todayDate = today.toISOString().split("T")[0];

const DailyActivities = () => {
  const INITIAL_STATE = {
    datetimefrom: todayDate,
    datetimeto: todayDate,
    // costcenterid: null,
  };

  const { user, setUser } = useUser();

  const { state, setLastClocking } = useContext(ClockingsContext);
  const costcenterid = state.lastClocking?.costcenterid;

  const { addFileToActivities, addCommentToActivities } = useContext(
    DailyActivitiesContext
  );

  const {
    state: { costCenterForId },
    setCostCenterForId,
  } = useContext(CostCenterContext);

  const [filerButton, setFilterButton] = useState({
    today: true,
    week: false,
    month: false,
  });

  const dictionary = useDictionary();
  const validations = [
    ({ datetimefrom, datetimeto }) =>
      isDateGreater(datetimefrom, datetimeto) || {
        datetimefrom: dictionary["reports"]["errors"]["startGreaterEnd"],
      },
    ({ datetimefrom, costcenterid }) =>
      isRequired(datetimefrom) ||
      isRequired(costcenterid !== "ALL" ? costcenterid : null) || {
        datetimefrom: "Data Richiesta",
        costcenterid: "Centro di Costo Richiesto",
      },
  ];

  const {
    state: { dailyActivities },
    getActivities,
  } = useContext(DailyActivitiesContext);

  const {
    values: searchDailyAct,
    changeHandler,
    setValues,
    errors,
    touched,
    isValid,
    reset,
  } = useForm(INITIAL_STATE, validations);

  const [comment, setComment] = useState("");
  const [isNewComment, setIsNewComment] = useState(false);

  const handleAddComment = async () => {
    if (comment.trim()) {
      try {
        // Aspetta che il commento venga aggiunto
        await addCommentToActivities(costcenterid, comment, user.id);

        // Resetta il campo di input e stato
        setComment("");
        setIsNewComment(false);

      } catch (error) {
        console.error("Errore durante l'aggiunta del commento:", error);
        sendNotification(
          "Errore Aggiunta Commento",
          "Si è verificato un errore durante l'aggiunta del commento.",
          "error",
          5
        );
      }
    }
  };

  const fileInputRef = useRef(null);
  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleReset = () => {
    reset();
    setFilterButton({
      today: true,
      week: false,
      month: false,
    });
    getActivities(user, todayDate, todayDate, costcenterid);
  };

  const handleFileChange = async (event) => {
    const fileInput = event.target; // Riferimento all'input file
    const file = fileInput.files[0];

    if (file) {
      // Controllo la dimensione del file (in bytes)
      if (file.size > 5485760) {
        sendNotification(
          "Errore Caricamento File",
          "Il file è troppo grande. Dimensione massima consentita: 5 MB.",
          "error",
          5
        );
        fileInput.value = "";
        return; // Interrompe l'esecuzione se il file è troppo grande
      }

      try {
        // Leggi il file come base64 e attendi il risultato
        const base64CleanString = await new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => {
            const base64 = reader.result;
            resolve(base64.split(",")[1]);
          };
          reader.onerror = (err) => {
            reject(err);
          };
          reader.readAsDataURL(file);
        });

        // Aggiungi il file alle attività
        await addFileToActivities(costcenterid, {
          base64: base64CleanString,
          name: file.name,
        });

        console.log("File aggiunto con successo.");
      } catch (error) {
        console.error("Errore durante il caricamento del file:", error);
        sendNotification(
          "Errore Caricamento File",
          "Si è verificato un errore durante il caricamento del file.",
          "error",
          5
        );
      } finally {
        fileInput.value = ""; // Pulisci l'input file
      }
    }
  };

  useEffect(() => {
    setCostCenterForId(costcenterid);
    getActivities(user, todayDate, todayDate, costcenterid);
  }, [costcenterid]);

  useEffect(() => {
    setLastClocking();
  }, []);

  // Get the date seven days from now
  const lastWeek = new Date(today);
  lastWeek.setDate(today.getDate() - 7);
  const lastWeekDate = lastWeek.toISOString().split("T")[0];

  // Get the date one month from now
  const lastMonth = new Date(today);
  lastMonth.setMonth(today.getMonth() - 1);
  const lastMonthDate = lastMonth.toISOString().split("T")[0];

  const ButtonFastFilter = () => {
    return (
      <Grid item container spacing={3}>
        <Grid item xs={12} md={12} lg={4}>
          <Button
            id="today-button"
            fullWidth
            startIcon={<Today />}
            variant={filerButton.today ? "contained" : "outlined"}
            onClick={() => {
              setFilterButton({ today: true, week: false, month: false }),
                setValues({
                  ...searchDailyAct,
                  datetimefrom: todayDate,
                  datetimeto: todayDate,
                });
              getActivities(user, todayDate, todayDate, costcenterid);
            }}
          >
            {dictionary["dailyactivities"]["showToday"]}
          </Button>
        </Grid>

        <Grid item xs={12} md={12} lg={4}>
          <Button
            id="week-button"
            fullWidth
            startIcon={<CalendarViewMonth />}
            variant={filerButton.week ? "contained" : "outlined"}
            onClick={() => {
              setFilterButton({ today: false, week: true, month: false }),
                setValues({
                  ...searchDailyAct,
                  datetimefrom: lastWeekDate,
                  datetimeto: todayDate,
                });
              getActivities(user, lastWeekDate, todayDate, costcenterid);
            }}
          >
            {dictionary["dailyactivities"]["showWeek"]}
          </Button>
        </Grid>
        <Grid item xs={12} md={12} lg={4}>
          <Button
            id="month-button"
            fullWidth
            startIcon={<CalendarMonth />}
            variant={filerButton.month ? "contained" : "outlined"}
            onClick={() => {
              setFilterButton({ today: false, week: false, month: true }),
                setValues({
                  ...searchDailyAct,
                  datetimefrom: lastMonthDate,
                  datetimeto: todayDate,
                });
              getActivities(user, lastMonthDate, todayDate, costcenterid);
            }}
          >
            {dictionary["dailyactivities"]["showMonth"]}
          </Button>
        </Grid>
      </Grid>
    );
  };

  return (
    <Stack spacing={2}>
      <Card>
        <IconBox Icon={Search} />
        <CardHeader
          title={`${dictionary["dailyactivities"]["searchTitle"]} - ${costCenterForId}`}
          sx={{ pl: (theme) => theme.spacing(12) }}
        />
        <CardContent component="form">
          <Grid container spacing={2} mb={1}>
            <Grid item xs={12}>
              <ButtonFastFilter />
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                spacing={2}
                alignItems="center"
                justifyContent="flex-start"
              >
                <Grid item>
                  <DatePicker
                    label={dictionary["reports"]["dateFrom"]}
                    inputFormat="DD/MM/yyyy"
                    value={searchDailyAct.datetimefrom || ""}
                    onChange={(newValue) =>
                      changeHandler(
                        "datetimefrom",
                        newValue?.format("yyyy-MM-DD")
                      )
                    }
                    renderInput={(params) => (
                      <TextField
                        id="field-datafrom"
                        size="small"
                        {...params}
                        sx={{ textTransform: "capitalize" }}
                        error={Boolean(
                          touched.datetimefrom && errors.datetimefrom
                        )}
                        helperText={touched.datetimefrom && errors.datetimefrom}
                      />
                    )}
                  />
                </Grid>
                <Grid item>
                  <DatePicker
                    id="field-datato"
                    label={dictionary["reports"]["dateTo"]}
                    inputFormat="DD/MM/yyyy"
                    value={searchDailyAct.datetimeto || ""}
                    onChange={(newValue) =>
                      changeHandler(
                        "datetimeto",
                        newValue?.format("yyyy-MM-DD")
                      )
                    }
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        {...params}
                        sx={{ textTransform: "capitalize" }}
                        error={Boolean(touched.datetimeto && errors.datetimeto)}
                        helperText={touched.datetimeto && errors.datetimeto}
                      />
                    )}
                  />
                </Grid>
                <Grid item>
                  <Button
                    id="search-activity-btn"
                    variant="contained"
                    color="success"
                    size="small"
                    sx={{
                      textTransform: "capitalize",
                    }}
                    startIcon={<Search />}
                    type="submit"
                    onClick={(e) => {
                      e.preventDefault();
                      getActivities(
                        user,
                        searchDailyAct.datetimefrom,
                        searchDailyAct.datetimeto,
                        costcenterid
                      );
                      setFilterButton({
                        today: false,
                        week: false,
                        month: false,
                      });
                    }}
                  >
                    {dictionary["search"]}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    id="reset-button"
                    variant="contained"
                    color="warning"
                    size="small"
                    sx={{
                      textTransform: "capitalize",
                    }}
                    startIcon={<Refresh />}
                    onClick={handleReset}
                  >
                    {dictionary["reset"]}
                  </Button>
                </Grid>
              </Grid>
            </Grid>

            {/* <Grid item xs={12} md={6} lg={6}>
              <TextField
                fullWidth
                select
                id="filled-password-input"
                label={dictionary["dailyactivities"]["form"]["costcenter"]}
                type="text"
                autoComplete="current-password"
                value={searchDailyAct.costcenterid || "ALL"}
                error={Boolean(touched.costcenterid && errors.costcenterid)}
                helperText={touched.costcenterid && errors.costcenterid}
                size="small"
                onChange={(e) => changeHandler("costcenterid", e.target.value)}
              >
                <MenuItem value={"ALL"}>Tutti</MenuItem>
                {costcenters.map((res, idx) => (
                  <MenuItem key={idx} value={res.id}>
                    {res.description}
                  </MenuItem>
                ))}
              </TextField>
            </Grid> */}

            {/* <Grid item xs={12} md={12} lg={12}>
              <Stack direction={"row"} spacing={2}>
                <FormGroup sx={{ width: "45%" }}>
                  <FormLabel component="legend">Stato</FormLabel>
                  <Grid item container>
                    <Grid item xs={12} md={12} lg={4}>
                      <FormControlLabel
                        control={
                          <Checkbox
                        id="terminated-filter"
                            checked={searchDailyAct.terminated}
                            onChange={(e) =>
                              changeHandler("terminated", e.target.checked)
                            }
                          />
                        }
                        label={dictionary["dailyactivities"]["terminated"]}
                      />
                    </Grid>
                    <Grid item xs={12} md={12} lg={4}>
                      <FormControlLabel
                        control={
                          <Checkbox
                        id="tostart-filter"
                            checked={searchDailyAct.tostart}
                            onChange={(e) =>
                              changeHandler("tostart", e.target.checked)
                            }
                          />
                        }
                        label={dictionary["dailyactivities"]["notstarted"]}
                      />
                    </Grid>
                    <Grid item xs={12} md={12} lg={4}>
                      <FormControlLabel
                        control={
                          <Checkbox
                        id="started-filter"
                            checked={searchDailyAct.started}
                            onChange={(e) =>
                              changeHandler("started", e.target.checked)
                            }
                          />
                        }
                        label={dictionary["dailyactivities"]["start"]}
                      />
                    </Grid>
                  </Grid>
                </FormGroup>
              </Stack>
            </Grid> */}
            <Grid item xs={12} md={12} lg={12}>
              <Stack direction="row" justifyContent={"flex-start"} spacing={2}>
                <label htmlFor="file-upload-input">
                  <input
                    id="file-upload-input"
                    type="file"
                    accept="*/*"
                    multiple
                    style={{ display: "none" }}
                    ref={fileInputRef}
                    onChange={async (e) => {
                      await handleFileChange(e); // Aspetta che il file venga gestito
                      getActivities(
                        user,
                        searchDailyAct.datetimefrom,
                        searchDailyAct.datetimeto,
                        costcenterid
                      );
                    }}
                  />
                  {/* Bottone per aprire il selettore di file */}
                  <Button
                    id="search-activity-btn"
                    variant="contained"
                    color="primary"
                    size="small"
                    sx={{
                      textTransform: "capitalize",
                    }}
                    startIcon={<NoteAddOutlinedIcon />}
                    onClick={handleButtonClick}
                  >
                    {dictionary["dailyactivities"]["addfile"]}
                  </Button>
                </label>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  sx={{
                    textTransform: "capitalize",
                  }}
                  startIcon={<AddCommentOutlinedIcon />}
                  onClick={() => setIsNewComment(true)}

                  // funzione multipla setfilterbutton a false quando fa il reset, forse meglio use effect cosi facciamo ricaricare anche la lista
                >
                  {dictionary["dailyactivities"]["addcomments"]}
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      {/* Modale per i commenti */}
      <Dialog
        open={isNewComment}
        onClose={() => setIsNewComment(false)}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="form-dialog-title">Aggiungi un commento</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            placeholder="Scrivi un commento..."
            value={comment}
            autoFocus
            onKeyDown={async (e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                await handleAddComment();
                getActivities(
                  user,
                  searchDailyAct.datetimefrom,
                  searchDailyAct.datetimeto,
                  costcenterid
                );
              }
            }}
            onChange={(e) => setComment(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsNewComment(false)} color="secondary">
            Annulla
          </Button>
          <Button
            onClick={async () => {
              await handleAddComment(); // Aspetta che il commento venga aggiunto
              getActivities(
                user,
                searchDailyAct.datetimefrom,
                searchDailyAct.datetimeto,
                costcenterid
              );
            }}
            color="primary"
            disabled={!comment.trim()}
            startIcon={<SendOutlinedIcon />}
          >
            Invia
          </Button>
        </DialogActions>
      </Dialog>

      <DailyActivitiesTabels searchedDailyAct={dailyActivities} mini />
    </Stack>
  );
};

export default DailyActivities;
