import React, { useContext, useEffect } from "react";
import { CostCenterContext } from "../contexts";

const useCostCenterUser = (position, rights, datetimefrom, datetimeto) => {
  const {
    state: { costcentersforuser },
    setCostCenterForUser,
  } = useContext(CostCenterContext);
  
  useEffect(() => {
    let user =  localStorage.getItem("bitPresenzeclient/user")
    let userParse = JSON.parse(user)
    setCostCenterForUser(position, userParse.id, rights, datetimefrom, datetimeto);
  }, [position]);

  return costcentersforuser;
};

export default useCostCenterUser;
