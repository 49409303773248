import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useRef } from "react";
import { useDictionary, useUser } from "../hooks";
import {
  AddTwoTone,
  AdminPanelSettingsTwoTone,
  CalendarMonth,
  Delete,
  DoneAll,
  Edit,
  EventBusyOutlined,
  EventRepeat,
  GroupAdd,
  PersonTwoTone,
  PlayArrowRounded,
  StartTwoTone,
  StopCircleRounded,
  Visibility,
  Warning,
} from "@mui/icons-material";
import { DailyActivitiesContext } from "../contexts";
import { openModal } from "../hooks/useModals";
import DailyActivitiesForm from "./DailyActivitiesForm";
import { red } from "@mui/material/colors";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import LoginOutlinedIcon from "@mui/icons-material/LoginOutlined";
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";
import AddCommentOutlinedIcon from "@mui/icons-material/AddCommentOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

const DailyActivitiesTabels = ({
  mini = false,
  onlyView = false,
  searchedDailyAct,
  manage = false,
}) => {
  const dictionary = useDictionary();
  const { user, geolocationEnabled } = useUser();
  const navigate = useNavigate();
  const DATE_FORMAT = "DD-MM-YYYY";
  const {
    startActivities,
    stopActivities,
    pauseActivities,
    getUserListForSupervisor,
    deleteActivities,
    downloadFile,
    updateDailyActivities,
  } = useContext(DailyActivitiesContext);

  useEffect(() => {
    if (manage) {
      getUserListForSupervisor();
    }
  }, []);

  const timeRef = useRef();
  const dateRef = useRef();
  const notesRef = useRef();
  const positionRef = useRef();

  const formatDate = (date) => {
    const parsedDate = new Date(date); // Converte in oggetto Date
    const day = String(parsedDate.getDate()).padStart(2, "0");
    const month = String(parsedDate.getMonth() + 1).padStart(2, "0"); // Mesi da 0 a 11
    const year = parsedDate.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const formatTime = (time) => {
    const [hours, minutes] = time.split(":"); // Divide la stringa in ore, minuti, secondi
    return `${hours}:${minutes}`;
  };

  // const handleStartActClock = (direction, item) => {
  //   openModal(
  //     direction === "OUT"
  //       ? "Termina Intervento"
  //       : direction === "PAUSE"
  //       ? "Blocca Intervento"
  //       : "Inizia Intervento",
  //     <DailyActivitiesForm
  //       direction={direction}
  //       title={item.title}
  //       costcenter={item.costcenter}
  //       geolocationEnabled={geolocationEnabled}
  //       dateRef={dateRef}
  //       timeRef={timeRef}
  //       notesRef={notesRef}
  //       positionRef={positionRef}
  //       dictionary={dictionary}
  //     />,
  //     null,
  //     (closeModal) => (
  //       <Stack direction="row" spacing={2}>
  //         <Button
  //           color="success"
  //           variant="contained"
  //           size="small"
  //           onClick={() =>
  //             direction === "OUT"
  //               ? stopActivities(
  //                   dateRef.current,
  //                   timeRef.current,
  //                   closeModal,
  //                   positionRef,
  //                   user,
  //                   item,
  //                   manage,
  //                   false,
  //                   notesRef.current
  //                 )
  //               : direction === "PAUSE"
  //               ? pauseActivities(
  //                   dateRef.current,
  //                   timeRef.current,
  //                   closeModal,
  //                   positionRef,
  //                   user,
  //                   item,
  //                   manage,
  //                   notesRef.current
  //                 )
  //               : startActivities(
  //                   dateRef.current,
  //                   timeRef.current,
  //                   closeModal,
  //                   positionRef,
  //                   user,
  //                   item,
  //                   manage,
  //                   notesRef.current
  //                 )
  //           }
  //         >
  //           {dictionary["confirm"]}
  //         </Button>
  //         <Button
  //           color="error"
  //           variant="contained"
  //           size="small"
  //           onClick={closeModal}
  //         >
  //           {dictionary["cancel"]}
  //         </Button>
  //       </Stack>
  //     )
  //   );
  // };

  // const CustomToolbar = () => (
  //   <GridToolbarContainer>
  //     <Stack
  //       sx={{ mb: (theme) => theme.spacing(1) }}
  //       direction="row"
  //       justifyContent="flex-end"
  //       spacing={3}
  //     >
  //     </Stack>
  //   </GridToolbarContainer>
  // );

  const detailDailyActivities = (activities) => {
    navigate(
      `/daily-activities/${activities.id}?mode=view&role=${
        manage ? "manage" : "user"
      }`
    );
  };

  const editMode = (activities) => {
    navigate(
      `/daily-activities/${activities.id}?mode=edit&role=${
        manage ? "manage" : "user"
      }`
    );
  };

  return (
    <Stack spacing={1}>
      {searchedDailyAct?.map((row, i) =>
        !manage ? (
          <Card
            id={`card-${row.id}`}
            key={i + row.id + row.action}
            variant="outlined"
            sx={{ marginBottom: "1px" }}
          >
            {/* Stack Generale orizzontale per mettere header, content e bottone */}
            <Stack
              direction="row"
              alignItems="center"
              spacing={2}
              sx={{ width: "100%" }}
            >
              {/* Stack verticale per CardHeader e CardContent */}
              <Stack direction="column" flex={1} spacing={1}>
                <CardHeader
                  sx={{ padding: "0px !important" }}
                  avatar={
                    <Stack direction="row" justifyContent="center">
                      {row.action === "CLOCKING_IN" && (
                        <IconButton size="sm">
                          <LoginOutlinedIcon color="primary" />
                        </IconButton>
                      )}
                      {row.action === "CLOCKING_OUT" && (
                        <IconButton size="sm">
                          <LogoutOutlinedIcon color="primary" />
                        </IconButton>
                      )}
                      {row.action === "FILE_ADD" && (
                        <IconButton size="sm">
                          <NoteAddOutlinedIcon color="primary" />
                        </IconButton>
                      )}
                      {row.action === "COMMENT_ADD" && (
                        <IconButton size="sm">
                          <AddCommentOutlinedIcon color="primary" />
                        </IconButton>
                      )}
                    </Stack>
                  }
                  title={
                    <>
                      <Typography
                        style={{
                          fontSize: 17,
                          fontWeight: "bold",
                          display: "inline",
                        }}
                      >
                        {`${formatDate(row.clientdate)} alle ${formatTime(
                          row.clienttime
                        )}`}
                      </Typography>
                      <Typography
                        style={{
                          fontSize: 15,
                          fontWeight: "bold",
                          color: "gray",
                          display: "inline",
                        }}
                      >
                        {` - ${row.firstname} ${row.lastname} - ${
                          dictionary["diaryactions"][row.action]
                        }`}
                      </Typography>
                    </>
                  }
                />

                {/* CardContent */}
                {(row.comment || row.filename) && (
                  <CardContent sx={{ padding: "0px !important" }}>
                    <Typography
                      sx={{
                        marginTop: "-15px", // Rimosso spazio extra tra Header e Content
                        ml: "57px !important",
                      }}
                    >
                      {`"${row.comment || row.filename} ${
                        row.filedescription
                      }"`}
                    </Typography>
                  </CardContent>
                )}
              </Stack>

              {/* Stack orizzontale per il bottone */}
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                {row.action === "FILE_ADD" && (
                  <IconButton
                    aria-label="delete"
                    size="sm"
                    onClick={() =>
                      downloadFile(row.idcostcenter, row.fileid, row.filename)
                    }
                  >
                    <FileDownloadOutlinedIcon
                      sx={{ fontSize: 35 }}
                      color="info"
                    />
                  </IconButton>
                )}
              </Stack>
            </Stack>
          </Card>
        ) : (
          <Card
            id={`card-${row.id}`}
            key={i + row.id + row.action}
            variant="outlined"
            sx={{ marginBottom: "1px" }}
          >
            {/* Stack Generale orizzontale per mettere header, content e bottone */}
            <Stack
              direction="row"
              alignItems="center"
              spacing={2}
              sx={{ width: "100%" }}
            >
              {/* Stack verticale per CardHeader e CardContent */}
              <Stack direction="column" flex={1} spacing={1}>
                <CardHeader
                  sx={{ padding: "0px !important" }}
                  // avatar={
                  //   // <Stack direction="row" justifyContent="center">
                  //   //   {row.action === "CLOCKING_IN" && (
                  //   //     <IconButton size="sm">
                  //   //       <LoginOutlinedIcon color="primary" />
                  //   //     </IconButton>
                  //   //   )}
                  //   //   {row.action === "CLOCKING_OUT" && (
                  //   //     <IconButton size="sm">
                  //   //       <LogoutOutlinedIcon color="primary" />
                  //   //     </IconButton>
                  //   //   )}
                  //   //   {row.action === "FILE_ADD" && (
                  //   //     <IconButton size="sm">
                  //   //       <NoteAddOutlinedIcon color="primary" />
                  //   //     </IconButton>
                  //   //   )}
                  //   //   {row.action === "COMMENT_ADD" && (
                  //   //     <IconButton size="sm">
                  //   //       <AddCommentOutlinedIcon color="primary" />
                  //   //     </IconButton>
                  //   //   )}
                  //   // </Stack>
                  // }
                  title={
                    <>
                      <Typography
                        style={{
                          fontSize: 17,
                          fontWeight: "bold",
                          display: "inline",
                        }}
                      >
                        {`${row.costcenter} il giorno ${formatDate(
                          row.plannedstartactivities
                            ? row.plannedstartactivitydate
                            : row.notbeforedate
                        )}`}
                      </Typography>
                      <Typography
                        style={{
                          fontSize: 15,
                          fontWeight: "bold",
                          color: "gray",
                          display: "inline",
                        }}
                      >
                        {` - Assegnato a ${row.user}`}
                      </Typography>
                    </>
                  }
                />
              </Stack>
              <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                <IconButton
                  aria-label="delete"
                  size="sm"
                  onClick={() => {
                    navigate(
                      `/daily-activities/${row.id}?mode=edit&role=manage`
                    );
                  }}
                >
                  <Edit sx={{ fontSize: 25 }} color="info" />
                </IconButton>
              </Stack>
            </Stack>
          </Card>
        )
      )}
    </Stack>
  );
};

export default DailyActivitiesTabels;
